import React, { memo } from "react";

import { Switch, Redirect } from "react-router";

import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

import Error404 from "../views/errors/error404";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/", component: () => <Redirect to="/product/productlist" /> },

  {
    path: "/product/productlist",
    component: Default,
  },
  {
    path: "/product/details/:productID",
    component: Default,
  },
  {
    path: "/product/create",
    component: Default,
  },
  {
    path: "/product/update/:productID",
    component: Default,
  },
  {
    path: "/product/details/:productID/createvariant",
    component: Default,
  },
  {
    path: "/product/details/:productID/varient/:varientID",
    component: Default,
  },
  {
    path: "/product/varient-types",
    component: Default,
  },
  {
    path: "/product/review",
    component: Default,
  },

  {
    path: "/category/categoryList",
    component: Default,
  },
  {
    path: "/category/create",
    component: Default,
  },
  {
    path: "/category/:name/subcategory",
    component: Default,
  },
  {
    path: "/subcategory/:name/supersubcategory",
    component: Default,
  },

  {
    path: "/brand/brandlist",
    component: Default,
  },
  {
    path: "/brand/create",
    component: Default,
  },

  {
    path: "/order/orderList",
    component: Default,
  },
  {
    path: "/order/:status/orderList",
    component: Default,
  },
  {
    path: "/order/details/:orderID",
    component: Default,
  },

  {
    path: "/customer/customerList",
    component: Default,
  },

  {
    path: "/refund/refundlist",
    component: Default,
  },
  {
    path: "/refund/refundsetting",
    component: Default,
  },

  {
    path: "/seller/sellerList",
    component: Default,
  },
  {
    path: "/seller/packagepayment",
    component: Default,
  },
  {
    path: "/seller/earninghistory",
    component: Default,
  },
  {
    path: "/seller/payout",
    component: Default,
  },
  {
    path: "/seller/payoutrequest",
    component: Default,
  },

  {
    path: "/dealers",
    component: Default,
  },

  {
    path: "/pincodes",
    component: Default,
  },

  {
    path: "/reports/sales-report",
    component: Default,
  },

  {
    path: "/reports/stock-report",
    component: Default,
  },

  {
    path: "/warehouse/warehouselist",
    component: Default,
  },

  {
    path: "/offer/offerlist",
    component: Default,
  },
  {
    path: "/offer/addoffer",
    component: Default,
  },
  {
    path: "/offer/editoffer/:id",
    component: Default,
  },
  {
    path: "/offer/offerbannerlist",
    component: Default,
  },
  {
    path: "/offer/offercountdownlist",
    component: Default,
  },
  {
    path: "/offer/volumeofferslist",
    component: Default,
  },

  { path: "/coupons/:active_tab?", component: Default },

  { path: "/returnedorder/request", component: Default },
  { path: "/returnedorder/requestdetail/:id", component: Default },
  { path: "/returnedorder/orders", component: Default },
  { path: "/returnedorder/orderdetails/:id", component: Default },

  {
    path: "/leaflet/leafletList",
    component: Default,
  },
  {
    path: "/appsettings",
    component: Default,
  },
  {
    path: "/errors/error404",
    component: Simple,
  },
];

const authRoutes = [
  { path: "/danger/delete-account", component: Simple },
  { path: "/account/delete-success", component: Simple },
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
  { path: "/privacy-policy", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
        <Error404 />
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
