import { createRoot } from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";

import { QueryClient, QueryClientProvider } from "react-query";

import { Toaster } from "react-hot-toast";

import { store } from "./store";

import "./i18n";

const queryClient = new QueryClient();

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <BrowserRouter basename="/">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
        <Toaster position="top-right" reverseOrder={false} />
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
);
