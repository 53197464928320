import React from "react";

const Loading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <lottie-player
        src="https://lottie.host/2f8ce05a-a3e6-481a-be84-11f23f715776/VzrQT8mkVe.json"
        background="transparent"
        speed="1"
        style={{ width: "290px", height: "290px" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};

export default Loading;
