import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getHorizontalList,
  createHorizontalList,
  deleteHorizontal,
  createCustomNotification,
} from "./settingsUrls";

let options = {
  staleTime: 30000,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  poll: 500,
};

const Horizontal_list = () => {
  return useQuery(["horizontalList"], () => getHorizontalList(), {
    ...options,
  });
};

const Create_horizontal = () => {
  const queryClient = useQueryClient();
  return useMutation((item) => createHorizontalList(item), {
    onMutate: async (newItem) => {
      await queryClient.cancelQueries("horizontalList");

      const prev = queryClient.getQueryData("horizontalList");
      return {
        prev,
        newItem,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("horizontalList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("horizontalList", context.prev);
    },
  });
};

const CreateCustomNotification = () => {
  return useMutation((item) => createCustomNotification(item));
};

const Delete_horizontal = () => {
  const queryClient = useQueryClient();
  return useMutation(({ horizontalID }) => deleteHorizontal({ horizontalID }), {
    onMutate: async (subcategory) => {
      await queryClient.cancelQueries("horizontalList");
      const prev = queryClient.getQueryData("horizontalList");
      return {
        prev,
        subcategory,
      };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("horizontalList", context.prev);
    },
    onSettled: () => {
      queryClient.invalidateQueries("horizontalList");
    },
  });
};

const useSettingsQuery = {
  Horizontal_list,
  Create_horizontal,
  Delete_horizontal,
  CreateCustomNotification,
};

export default useSettingsQuery;
