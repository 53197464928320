import { useState, useEffect, memo, Fragment } from "react";
import { Nav, Dropdown, Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import RadioBtn from "../../../setting/elements/radio-btn";
import CustomToggle from "../../../dropdowns";
import * as SettingSelector from "../../../../store/setting/selectors";
import { theme_scheme_direction } from "../../../../store/setting/reducers";
import CreateNotification from "../../../../views/AppSettings/CreateNotification";

import { FaBell } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";

const Headerpro = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  const mode = useSelector(SettingSelector.theme_scheme_direction);

  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const [toggleNotificationCreate, setToggleNotificationCreate] =
    useState(false);

  const [lang, setLang] = useState(mode === "rtl" ? "ab" : "en");

  const { i18n, t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (lang === "en") {
      dispatch(theme_scheme_direction("ltr"));
      changeLanguage("en");
    } else if (lang === "ab") {
      dispatch(theme_scheme_direction("rtl"));
      changeLanguage("ab");
    }
  }, [lang]);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);

    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {
      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });

  const logout = () => {
    localStorage.clear();
    history.push("/auth/sign-in");
  };

  const [show, setShow] = useState(true);

  const [show1] = useState(false);

  const fullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-end align-item-center bg-white py-3 pe-4">
          {/* {user?.role === 1 && (
            <div
              className="d-flex align-items-center me-4 iq-font-style"
              role="group"
              aria-label="First group"
            >
              <Button onClick={() => setToggleNotificationCreate(true)}>
                <RiMailSendLine />
              </Button>
            </div>
          )} */}
          <div
            className="d-flex align-items-center mr-2 iq-font-style"
            role="group"
            aria-label="First group"
          >
            <RadioBtn
              btnName="theme_font_size"
              labelclassName="  border-0 btn-icon btn-sm"
              id="font-size-sm"
              defaultChecked={themeFontSize}
              value="theme-fs-sm"
            >
              <span className="mb-0 h6" style={{ color: "inherit" }}>
                A
              </span>
            </RadioBtn>
            <RadioBtn
              btnName="theme_font_size"
              labelclassName="  border-0 btn-icon"
              id="theme-fs-md"
              defaultChecked={themeFontSize}
              value="theme-fs-md"
            >
              <span className="mb-0 h4" style={{ color: "inherit" }}>
                A
              </span>
            </RadioBtn>
            <RadioBtn
              btnName="theme_font_size"
              labelclassName="  border-0 btn-icon"
              id="theme-fs-lg"
              defaultChecked={themeFontSize}
              value="theme-fs-lg"
            >
              <span className="mb-0 h2" style={{ color: "inherit" }}>
                A
              </span>
            </RadioBtn>
          </div>

          <Nav.Item
            className=" iq-full-screen d-none d-xl-block me-3 ms-3"
            onClick={() => setShow(!show)}
          >
            <Nav.Link id="btnFullscreen" onClick={fullscreen}>
              <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                <span className="btn-inner">
                  <svg
                    className={`normal-screen ${
                      show === true ? "" : "d-none"
                    } icon-24`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5528 5.99656L13.8595 10.8961"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M5.8574 18.896L10.5507 13.9964"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <svg
                    className={`full-normal-screen ${
                      show === false ? "" : "d-none"
                    } icon-24`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7542 10.1932L18.1867 5.79319"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M10.4224 13.5726L5.82149 18.1398"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </Nav.Link>
          </Nav.Item>

          <div className="d-flex gap-3">
            <Form.Select
              className="form-control-sm me-3 d-none d-xl-block"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              <option value="en">English</option>
              <option value="ab">Arabic</option>
            </Form.Select>
            {/* <div className="mt-2">
              <Button
                className="bell-button mx-3"
                onClick={() => setShowModal(!showModal)}
              >
                <div style={{ color: "grey" }}>
                  <FaBell style={{ fontSize: "20px" }} />
                  <span className="bell-span"></span>
                </div>
              </Button>
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                className="show-modal"
              >
                <div>
                  <Nav justify variant="tabs" defaultActiveKey="/home">
                    <Nav.Item>
                      <Nav.Link>Unread(2)</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link>Archived</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Modal>
            </div> */}

            <Dropdown className="my-auto">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="d-flex flex-row justify-content-center  btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="32"
                      className="icon-32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu variant="end">
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {toggleNotificationCreate && (
        <CreateNotification
          toggle={toggleNotificationCreate}
          setToggle={setToggleNotificationCreate}
        />
      )}
    </Fragment>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;
