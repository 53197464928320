import { memo, useState, Fragment, useEffect } from "react";

// header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

//sidebar
import Sidebar from "../../components/partials/dashboard/sidebarstyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

//default
import DefaultRouter from "../../router/default-router";

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useSelector, useDispatch } from "react-redux";

const Default = memo(() => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);

  var subHeader = "";
  var commanclass = "";

  return (
    <Fragment>
      <Loader />
      <Sidebar app_name={appName} />
      <main
        className={`main-content`}
        style={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}
      >
        <div className={`${commanclass} position-fixed header-width `}>
          <Headerpro />
          {subHeader}
        </div>

        <div className={` ${pageLayout} content-inner pb-0`}>
          <DefaultRouter />
        </div>
      </main>
      <SettingOffCanvas />
      <Footer />
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
