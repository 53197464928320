import { memo, lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading from "../components/custom/loading";

const Dashboard = lazy(() => import("../views/dashboard/index"));

// product
const product = lazy(() => import("../views/Products/ProductList"));
const CreateProduct = lazy(() => import("../views/Products/CreateProduct"));
const UpdateProduct = lazy(() => import("../views/Products/UpdateProduct"));
const ProductDetails = lazy(() => import("../views/Products/ProductDetails"));
const review = lazy(() => import("../views/Products/Review"));

// category
const categoryList = lazy(() => import("../views/Category/CategoryList"));
const SubCategory = lazy(() =>import("../views/Category/SubCategory/SubCategory"));

// brand
const brandlist = lazy(() => import("../views/Brand/BrandList"));
const createBrand = lazy(() => import("../views/Brand/AddBrand"));

// Order
const OrderList = lazy(() => import("../views/Order/OrderList"));
const OrderDetail = lazy(() => import("../views/Order/OrderDetail"));

// customer
const customer = lazy(() => import("../views/Customer/Customer"));

// seller
const sellerList = lazy(() => import("../views/Sellers/SellerList"));

// dealers
const Dealers = lazy(() =>import("../views/Dealers/DealerList.js"));

// Pincodes
const PincodeList = lazy(() => import("../views/Pincodes/PincodeList"));


const AppSettings = lazy(() => import("../views/AppSettings/Index"));

const SalesReport = lazy(() => import("../views/Reports/SalesReport/Index.js"));

const StockReport = lazy(() => import("../views/Reports/StockReport/Index.js"));

const Error404 = lazy(() => import("../views/errors/error404"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/dashboard" exact component={Dashboard} />
            {/* product */}
            <Route path="/product/productlist" component={product} />
            <Route exact path="/product/create" component={CreateProduct} />
            <Route  exact path="/product/update/:productID" component={UpdateProduct}/>
            <Route exact path="/product/details/:productID" component={ProductDetails}/>
            <Route path="/product/review" component={review} />
            {/* category */}
            <Route path="/category/categoryList" component={categoryList} />
            <Route path="/category/:categoryID/subcategory" component={SubCategory} />
            {/* Brand */}
            <Route path="/brand/brandlist" component={brandlist} />
            <Route path="/brand/create" component={createBrand} />
            {/* Order */}
            <Route path="/order/orderList" component={OrderList} />
            <Route path="/order/:status/orderList" component={OrderList} />
            <Route path="/order/details/:orderID" component={OrderDetail} />
            {/* customer */}
            <Route path="/customer/customerList" component={customer} />
            {/* seller */}
            <Route path="/seller/sellerList" component={sellerList} />
            {/* dealers */}
            <Route path="/dealers" component={Dealers} />
            {/* inventory */}
            <Route path="/pincodes" component={PincodeList} />
            <Route path="/appsettings" component={AppSettings} />
            <Route path="/reports/sales-report" component={SalesReport} />
            <Route path="/reports/stock-report" component={StockReport} />
            <Route path="/errors/error404" component={Error404} />
            <Redirect from="*" to="/errors/error404" />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
