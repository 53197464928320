import DataService from "../DataService";

const getHorizontalList = async () => {
  let url = `admin_panel/horizontal-product-list/?limit=2000&offset=0`;
  const data = await DataService.get(url);
  return data.data;
};

const createHorizontalList = async (value) => {
  const url = `admin_panel/horizontal-product-list/`;
  const response = await DataService.post(url, value);
  return response;
};

const deleteHorizontal = async ({ horizontalID }) => {
  const url = `admin_panel/horizontal-product-list/${horizontalID}/`;
  const data = await DataService.del(url);
  return data;
};

const createCustomNotification = async (value) => {
  const url = `admin_panel/custom-notification/`;
  const response = await DataService.post(url, value);
  return response;
};

export {
  getHorizontalList,
  createHorizontalList,
  deleteHorizontal,
  createCustomNotification,
};
