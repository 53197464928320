import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { toast } from "react-hot-toast";

import useSettingsQuery from "../../store/appSettings/useSettingsQuery";

import S3 from "react-aws-s3";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";

window.Buffer = Buffer;

const modelSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  notification_type: Yup.number().required("Notification type is required"),
});

const initialValues = {
  title: "",
  description: "",
  image: "",
  notification_type: "",
};

function CreateNotification(props) {
  const { t } = useTranslation();

  const inputRef = useRef();
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);

  const envConfig = process.env;

  const config = {
    bucketName: envConfig.REACT_APP_S3_BUCKET_NAME,
    region: envConfig.REACT_APP_S3_REGION,
    dirName: "orion",
    accessKeyId: envConfig.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: envConfig.REACT_APP_S3_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);

  const { mutateAsync: createNotification, isSuccess } =
    useSettingsQuery.CreateCustomNotification();

  const formik = useFormik({
    initialValues,
    validationSchema: modelSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        let data = null;
        if (image !== null && image !== undefined) {
          data = await ReactS3Client.uploadFile(image);
        }

        let payload = {
          title: values.title,
          image: data?.location ? data?.location : "",
          description: values.description,
          notification_type: values.notification_type,
        };

        await createNotification(payload);
      } catch (error) {
        console.error(error);
        setStatus("Something went wrong");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Notification created successfully", {
        position: "top-center",
      });
      setLoading(false);
      props.setToggle(false);
    }
  }, [isSuccess]);

  return (
    <div className="modal" id="modal">
      <Modal size="md" centered show={props.toggle} onHide={props.setToggle}>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Body className="">
            <Modal.Header closeButton>
              <Modal.Title>{t("notificationcreate.title")} </Modal.Title>
            </Modal.Header>
          </Modal.Body>
          <Modal.Body className="mx-3">
            <Row>
              <Col>
                <Form.Label className="text-black">
                  {t("notificationcreate.form.title")}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid #CCDBE1" }}
                  {...formik.getFieldProps("title")}
                  className={clsx(
                    "form-control form-control-md form-control-solid",
                    {
                      "is-invalid": formik.touched.title && formik.errors.title,
                    }
                  )}
                  type="text"
                ></Form.Control>
                {formik.touched.title && formik.errors.title && (
                  <div className="fv-plugins-message-container text-danger mt-1">
                    <span role="alert">{formik.errors.title}</span>
                  </div>
                )}
              </Col>
              <Col>
                <Form.Label className="text-black">
                  {t("notificationcreate.form.notification_type")}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid #CCDBE1", height: "40px" }}
                  {...formik.getFieldProps("notification_type")}
                  className={clsx(
                    "form-control form-control-md form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.notification_type &&
                        formik.errors.notification_type,
                    }
                  )}
                  as="select"
                >
                  <option value="">Select Notification Type</option>
                  <option value={1}>Order Info</option>
                  <option value={2}>Offers</option>
                  <option value={3}>Payment</option>
                  <option value={4}>Other</option>
                </Form.Control>
                {formik.touched.notification_type &&
                  formik.errors.notification_type && (
                    <div className="fv-plugins-message-container text-danger mt-1">
                      <span role="alert">
                        {formik.errors.notification_type}
                      </span>
                    </div>
                  )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Form.Label className="text-black">
                  {" "}
                  {t("notificationcreate.form.description")}
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid #CCDBE1" }}
                  {...formik.getFieldProps("description")}
                  className={clsx(
                    "form-control form-control-md form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.description && formik.errors.description,
                    }
                  )}
                  as="textarea"
                  type="text"
                ></Form.Control>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container text-danger mt-1">
                    <span role="alert">{formik.errors.description}</span>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="d-flex flex-column">
                <div className="product-image">
                  <div
                    style={{
                      width: "300px",
                      margin: "0px auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={"file"}
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    {image && (
                      <img
                        src={URL.createObjectURL(image)}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    <div className="d-flex justify-content-center align-items-center">
                      <hr style={{ height: "1px", width: "80px" }}></hr>

                      <hr style={{ height: "1px", width: "80px" }}></hr>
                    </div>
                    <Button
                      onClick={() => inputRef.current.click()}
                      className="selectFiles"
                    >
                      {image ? t("common.change") : t("common.select")}
                    </Button>
                    <h4 className="uploadTypo"> upload 280*280 image</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end align-items-center mt-0 me-3">
              <Button
                className="btn btn-md "
                outline
                onClick={() => props.setToggle(false)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                className="btn btn-md ms-3 btn-primary text-white"
                type="submit"
              >
                {!loading && (
                  <span className="indicator-label"> {t("common.save")}</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    wait
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateNotification;
